<template>
  <div class="footer">
    <div class="container grid-md">
      <div class="columns">
        <div class="column">
          <h3>{{ $t("footer.headline") }}</h3>
          <ul class="footer__social">
            <li>
              <a
                href="https://www.linkedin.com/company/nordic-minds-gmbh"
                target="_blank"
                >LinkedIn</a
              >
            </li>
            <li>
              <a href="https://nordic-minds.com/blog" target="_blank"
                >nordicminds Blog</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="columns">
        <div class="column col-6 col-sm-12">
          <div class="columns">
            <div class="column col-6 col-md-6 col-sm-12">
              <h5>Hamburg</h5>
              <p>
                Hohe Bleichen 24-26<br />
                20354 Hamburg<br />
                Germany
              </p>
            </div>
            <div class="column col-6 col-md-6 col-sm-12">
              <h5>Düsseldorf</h5>
              <p>
                Hammer Straße 19<br />
                40219 Düsseldorf<br />
                Germany
              </p>
            </div>
            <div class="column col-6 col-md-6 col-sm-12">
              <h5>Stockholm</h5>
              <p>
                Nybrogatan 6<br />
                114 34 Stockholm<br />
                Sweden
              </p>
            </div>
            <div class="column col-6 col-md-6 col-sm-12">
              <h5>Helsinki</h5>
              <p>
                Mannerheimintie 12 B<br />
                00100 Helsinki<br />
                Finland
              </p>
            </div>
          </div>
        </div>
        <div class="column col-6 col-sm-12 footer__description">
          <p class="footer__description-text">{{ $t("footer.description") }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p class="footer__imprint margin--m">
            <span class="footer__link">© 2022 Nordic Minds</span>
            <router-link class="footer__link" to="/imprint">{{
              $t("navigation.imprint")
            }}</router-link>
            <router-link to="/privacy" class="footer__link">{{
              $t("navigation.privacy")
            }}</router-link>
            <a class="footer__link" @click="showGdprModal">Update cookie settings</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showGdprModal() {
      this.$modal.show('gdpr-modal');
    },
  }, 
}
</script>

<style scoped lang="scss">
.footer {
  @include font-size(16);

  background: $color-primary;
  padding: 2.5rem 0;
  color: $color-white;

  @media (min-width: $size-sm) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: $size-lg + 1) {
    padding-bottom: 5rem;
  }

  h4 {
    @include font-size(24);
  }

  h5 {
    @include font-size(18);
  }

  .column {
    order: 1;
  }

  .footer &__description {
    @media (max-width: $size-sm) {
      order: 0;
    }
  }

  &__description-text {
    @media (min-width: $size-sm) {
      margin-top: 0.45em;
      margin-bottom: 0;
    }

    @include font-size(18);
    font-weight: 600;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }

  &__link {
    @media (min-width: $size-sm) {
      margin-left: 1.5em;
    }

    border-bottom: 1px solid transparent;
    display: inline-block;
    font-family: $font-family-multi-display;
    letter-spacing: $html-letter-spacing;
    text-decoration: none;
    transition: border-color 0.5s ease-in-out;
    width: fit-content;
    color: $color-white;
    cursor: pointer;

    &[href] {
      &:hover {
        border-color: $color-white;
      }
    }

    &:first-child {
      @media (min-width: $size-sm) {
        order: 0;
        margin-left: 0;
      }

      order: 1;
    }
  }

  &__social {
    display: flex;
    list-style: none;
    margin: 2.5rem 0;

    a {
      display: block;
      height: 36px;
      line-height: 1000px;
      overflow: hidden;
      position: relative;
    }

    [href*="linkedin"] {
      background: url("../assets/images/icon-linkedin.svg") no-repeat center
        center;
      margin-right: 30px;
      width: 36px;
    }

    [href*="blog"] {
      background: url("../assets/images/icon-blog.svg") no-repeat center center;
      width: 145px;
    }
  }

  &__imprint {
    @media (min-width: $size-sm) {
      flex-direction: row;
    }

    @media (min-width: $size-lg) {
      margin-bottom: 5rem;
    }

    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}
</style>
