<template>
  <div class="header__outside">
    <headroom :down-tolerance="1024" :speed="500" :offset="10">
      <div
        class="header"
        :class="{ 'header--home': this.$route.name === 'home' }"
      >
        <div class="header__container">
          <div
            class="header__wrapper"
            v-bind:class="{ 'header__wrapper--nav-active': showMobileMenu }"
          >
            <router-link
              class="header__logo"
              to="/"
              v-on:click.native="showMobileMenu = false"
            >
              Nordic Minds
            </router-link>
            <div class="header__navigation">
              <router-link
                class="header__navigation-link"
                to="/service"
                v-on:click.native="showMobileMenu = false"
                v-bind:class="{
                  'header__navigation-link--active':
                    (this.$route.name === 'nordicMindsSearch')
                    | (this.$route.name === 'executiveSearch')
                    | (this.$route.name === 'boardServices'),
                }"
              >
                {{ $t("navigation.service") }}
              </router-link>
              <router-link
                class="header__navigation-link"
                to="/about"
                v-on:click.native="showMobileMenu = false"
                v-bind:class="{
                  'header__navigation-link--active':
                    (this.$route.path.indexOf('team') > 0)
                    | (this.$route.name === 'network')
                    | (this.$route.name === 'Positioning') | (this.$route.path.indexOf('advisory-board') > 0),
                }"
              >
                {{ $t("navigation.about_us") }}
              </router-link>
              <a
                href="https://nordic-minds.com/blog/"
                class="header__navigation-link"
                target="_blank"
              >
                {{ $t("navigation.insights") }}
              </a>
              <router-link
                class="header__navigation-link"
                to="/contact"
                v-on:click.native="showMobileMenu = false"
              >
                {{ $t("navigation.contact") }}
              </router-link>
              <div class="header__navigation-link">
                <LocaleChanger />
              </div>
              <a
                href="https://client.ezekia.com/login/nordic-minds/"
                class="header__navigation-link header__navigation-link--login"
                target="_blank"
              >
                {{ $t("navigation.login") }}
              </a>
            </div>
            <button
              class="header__navigation-toggle"
              v-on:click="showMobileMenu = !showMobileMenu"
            >
              <span
                class="header__navigation-toggle-bar header__navigation-toggle-bar--top"
              ></span>
              <span
                class="header__navigation-toggle-bar header__navigation-toggle-bar--center"
              ></span>
              <span
                class="header__navigation-toggle-bar header__navigation-toggle-bar--bottom"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </headroom>
  </div>
</template>

<script>
import { headroom } from "vue-headroom";
import LocaleChanger from "@/components/LocaleChanger.vue";

export default {
  name: "Header",
  components: {
    headroom,
    LocaleChanger,
  },
  data() {
    return {
      showMobileMenu: false,
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep .headroom {
  transition: transform 0.5s 0s ease-in-out !important;
  position: fixed !important;

  &--pinned {
    transition-delay: 0s !important;
  }
}

@media (max-width: $size-md) {
  .header {
    background-color: $gradient-color-start;
    position: fixed;
    width: 100%;
    z-index: 5;

    &:not(.header--home) {
      background-color: $color-white;
      background-image: none;
    }

    &__outside {
      position: fixed;
      width: 100vw;
      z-index: 10000;
    }

    &__wrapper {
      align-items: center;
      display: flex;
      height: $header-height-mobile;
      justify-content: space-between;
      margin: 0;
      padding: 0 $container-padding;
    }

    &__logo {
      background: url("../assets/images/nordic-minds-logo.svg") no-repeat;
      background-size: contain;
      display: block;
      height: 40px;
      overflow: hidden;
      position: relative;
      text-indent: 110%;
      white-space: nowrap;
      width: 8rem;
      z-index: 2;
    }

    &__navigation {
      height: calc(100vh - #{$header-height-mobile});
      left: 0;
      margin: $header-height-mobile 0 0;
      overflow-y: scroll;
      padding-top: 2rem;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: 0;
      transition: background-color 0s 0s $transition-easing;
      width: 100vw;

      .header__wrapper--nav-active & {
        background-color: $color-white;
        pointer-events: all;
        transition-delay: 0.5s;
      }

      &:before,
      &:after {
        background: $color-white;
        bottom: 0;
        content: "";
        opacity: 0;
        position: absolute;
        top: 0;
        transition: all $transition-duration * 2 $transition-easing;
        width: 100%;
        z-index: -1;
      }

      &:before {
        clip-path: polygon(100% 0, 0 100%, 0 0);
        left: 0;
        transform: translateY(-80%);

        .header__wrapper--nav-active & {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &:after {
        clip-path: polygon(100% 0, 0 100%, 100% 100%);
        right: 0;
        transform: translateY(80%);

        .header__wrapper--nav-active & {
          opacity: 1;
          transform: translateY(-1px);
        }
      }
    }

    &__navigation-link {
      color: $color-primary;
      display: block;
      font-family: $font-family-multi-display;
      font-size: 1rem;
      opacity: 0;
      padding: 0.5rem 1rem;
      transform: translateX(-2rem);
      transition: all $transition-duration * 2 $transition-easing;

      .header__wrapper--nav-active & {
        opacity: 1;
        transform: translateX(0);
        transition-delay: $transition-duration;
      }

      &.router-link-exact-active,
      &--active {
        font-weight: bold;
      }

      &--login {
        border: 1px solid $color-primary;
        margin: 1em auto;
        max-width: 12em;
        padding: 0.5rem 1.2rem;
        text-align: center;
        transition: all $transition-duration * 3 $transition-easing;
        width: calc(100% - 44px);
      }
    }

    &__navigation-toggle {
      align-items: center;
      background: transparent;
      border: 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 2.5rem;
      justify-content: center;
      outline: 0;
      padding: 0;
      position: relative;
      transition: all $transition-duration $transition-easing;
      width: 2.5rem;
      z-index: 2;

      .header__wrapper--nav-active & {
        transform: rotate(45deg);
      }

      &-bar {
        background: $color-primary;
        display: block;
        height: 4px;
        margin: 3.5px 0;
        transform-origin: center;
        transition: all $transition-duration $transition-easing;
        width: 36px;

        .header__wrapper--nav-active & {
          &--top {
            margin: 0;
            transform: rotate(0) translate(0, 2.5px);
          }

          &--center {
            height: 0;
            margin: 0;
            opacity: 0;
            width: 0;
          }

          &--bottom {
            margin: 0;
            transform: rotate(-90deg) translate(0.5px, 0);
          }
        }
      }
    }
  }
}
@media (min-width: $size-md + 1) {
  .header {
    background: transparent;
    height: $header-wrapper;
    transition: all $transition-duration * 2 $transition-easing;

    &__outside {
      pointer-events: none;
    }

    &__container {
      align-items: center;
      display: flex;
      flex-direction: column;
      pointer-events: all;
      transition: all $transition-duration * 2 $transition-easing;

      .headroom--not-top & {
        background-color: $color-white;
      }
    }

    &__wrapper {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      height: $header-height;
      justify-content: space-between;
      margin: 2.5rem 0;
      max-width: $size-2x;
      padding: 0 $container-padding;
      transition: all $transition-duration * 2 $transition-easing;
      width: 100%;

      .headroom--not-top & {
        margin: 0;
      }
    }

    &__logo {
      $logo-height: 2.5rem;
      $logo-width: 10.5rem;

      background: url("../assets/images/nordic-minds-logo.svg") no-repeat;
      background-size: contain;
      display: block;
      height: $logo-height;
      overflow: hidden;
      text-indent: 110%;
      transition: all $transition-duration * 2 $transition-easing;
      white-space: nowrap;
      width: $logo-width;

      &.router-link-exact-active {
        height: $logo-height;
      }

      .headroom--not-top & {
        transform: scale(0.66) translate(0.5rem, 0);
      }
    }

    &__navigation {
      height: 3.125rem;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
    }

    &__navigation-link {
      $padding-x: 0.75rem;
      cursor: pointer;
      display: inline-block;
      font-family: $font-family-multi-display;
      font-size: 0.8rem;
      letter-spacing: 0.04rem;
      line-height: 1.875rem;
      margin-left: Min(0.1rem, 0.75rem);
      margin-right: Min(0.1rem, 0.75rem);
      padding: 0 $padding-x;
      position: relative;
      transition: all $transition-duration $transition-easing;
      white-space: nowrap;

      &:before {
        background: $color-primary;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: $padding-x;
        opacity: 0;
        position: absolute;
        right: 5rem;
        transition: all $transition-duration * 3 $transition-easing;
      }

      &--active,
      &.router-link-exact-active {
        opacity: 1;

        &:before {
          left: $padding-x;
          right: $padding-x;
          opacity: 1;
        }
      }

      &:hover:before {
        opacity: 1;
        right: $padding-x;
      }

      &--login {
        border: 1px solid $color-primary;
        margin: 0 0 0 $padding-x;
        padding: 0 $padding-x;
        transition: all $transition-duration * 3 $transition-easing;

        &::before {
          display: none;
        }

        &.router-link-exact-active {
          background-color: $color-primary;
          color: $color-white;
        }

        &:hover {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }

    &__navigation-toggle {
      display: none;
    }
  }
}

@media (min-width: $size-xl + 1) {
  $padding-x: 0.75rem;

  .header__logo[data-v-61dd7a3d] {
    /* height: 3.5rem; */
  }

  .header__navigation-link {
    font-size: 0.9rem;
    margin: 0 $padding-x;
    padding: 0 $padding-x;
  }
}
</style>
