<template>
  <div id="app">
    <Header/>
    <div class="wrapper">
      <router-view/>
    </div>
    <Footer/>
    <BackToTop/>
    <vue-cookie-toggler :cookies-groups="cookiesGroups" save-label="Save" title="Privacy preference" settings-label="Individual Privacy Preferences"><p>We use cookies and other technologies on our website. Some of them are essential, while others help us to improve this website and your experience. Personal data may be processed (e.g. IP addresses), for example for personalized ads and content or ad and content measurement. You can find more information about the use of your data in our privacy policy.</p>
      <!-- (optional) use '#mainButtons' slot if you what to change the buttons in the main view (1st view) -->
    <template #mainButtons="{ accept, settings }">
      <button class="vct__btn vct__btn--default" @click="settings">
        Customize settings
      </button>
      <button class="vct__btn vct__btn--primary" @click="acceptClose(accept)">
        Accept all cookies
      </button>
    </template>
      <!-- (optional) use '#settingsButtons' slot if you what to change the buttons in Cookie Settings view (2nd view) -->
    <template #settingsButtons="{ accept, save }">
      <button class="vct__btn vct__btn--default" @click="acceptClose(accept)">
        Accept all cookies
      </button>
      <button class="vct__btn vct__btn--primary" @click="saveClose(save)">
        Save settings
      </button>
    </template>
    </vue-cookie-toggler>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import BackToTop from '@/components/BackToTop.vue'
import VueCookieToggler from 'vue-cookie-toggler'

export default {
  components: {
    Header,
    Footer,
    BackToTop,
    VueCookieToggler,
  },
  metaInfo () {
    const lang = localStorage.getItem("locale") || (navigator.language || navigator.userLanguage).split('-')[0]
    this.$i18n.locale = lang
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
    }
  },
  mounted: function() {
    this.check();
  },
  methods: {
    acceptClose(accept) {
      accept();
      this.$modal.hide('gdpr-modal');
      this.check();
    },
    saveClose(save) {
      save();
      this.$modal.hide('gdpr-modal');
      this.check();
    },
    check: function() {
      const cookieSettings = JSON.parse(localStorage.getItem('vct-cookie-settings'));
      if (cookieSettings) {
        const statisticsCategory = cookieSettings.data.find(item => item.category === 'statistics');
        if (statisticsCategory && statisticsCategory.active) {
          window.gtag('config', 'GTM-MMHQPXN');
          window.gtag('consent', 'update', {
            'ad_storage': 'granted',
              'analytics_storage': 'granted'
          });
          window.dataLayer.push({'event': 'ga4CookieOptIn'});
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      cookiesGroups: [
        {
          category: 'essential',
          name: 'Essential',
          description:'Essential cookies enable basic functions and are necessary for the proper function of the website.',
          active: true,
          required: true,
        },
        {
          category: 'statistics',
          name: 'Statistics',
          description:'Statistics cookies collect information anonymously. This information helps us to understand how our visitors use our website.',
          active: false,
          required: false,
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  @media (max-width: $size-md) {
    .wrapper {
      padding-top: $header-height-mobile;
      overflow: hidden;
    }
  }
</style>
