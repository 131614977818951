import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
// import VueAnalytics from "vue-analytics";
// import VueGtm from "vue-gtm";
import VueMeta from "vue-meta";
import VueAnimateOnScroll from "vue-animate-onscroll";
import "./assets/styles/nordicminds.scss";
// import VueGtm from '@gtm-support/vue2-gtm';
// import VueGtag from 'vue-gtag'

Vue.config.productionTip = false;
// const isProd = process.env.NODE_ENV === "production";

Vue.use(VueAnimateOnScroll);
Vue.use(VueMeta);

// Vue.use(VueAnalytics, {
//   id: "UA-143367913-1",
//   set: [{ field: "anonymizeIp", value: true }],
//   router,
//   ignoreRoutes: ["team", "/team"],
//   debug: {
//     // enabled: !isProd
//     enabled: isProd,
//   },
// });

// Vue.use(VueGtm, {
//   // id: "GTM-5QJL3VS",
//   id: "GTM-MMHQPXN",
//   enabled: false,
//   debug: true,
//   vueRouter: router,
//   ignoredViews: ["team", "/team"],
// });

// Vue.use(VueGtag, {
//   config: {
//       id: 'G-DJ4C77REBN',
//       params: {
//         send_page_view: false
//       }
//   }
// }, router)

const defaultPageTitle =
  "Nordic Minds - Executive Search and Talent Acquisition";
router.afterEach((to) => {
  document.title = to.meta.title || defaultPageTitle;
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
  metaInfo: {
    htmlAttrs: {
      lang: localStorage.getItem("locale"),
    },
  },
  mounted: function() {
    if (localStorage.getItem("locale")) {
      i18n.locale = localStorage.getItem("locale");
    }
  },
}).$mount("#app");
