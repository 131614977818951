<template>
  <div class="locale-changer">
      <div v-for="(lang, i) in langs" :key="`Lang${i}`">
        <button
          v-on:click="onLocaleChange($i18n, lang.lang)"
          v-if="$i18n.locale !== lang.lang"
          class="locale-button"
        >
          {{ langs[i].title }}
        </button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'LocaleChanger',
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.language
      },
    }
  },
  data () {
    return {
      langs: [
        {'lang': 'en',
         'title': 'EN'
        },
        {'lang': 'de',
         'title':'DE'
        }
      ],
      language: undefined,
    }
  },
  methods: {
    onLocaleChange: function ($i18n, lang) {
      localStorage.setItem('locale', lang)
      $i18n.locale = lang
      this.language = lang
    }
  }
}
</script>

<style lang="scss" scoped>
  .locale {
    
    &-button {
      @include font-size(16);

      background-color: transparent;
      border: none;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      color: $color-primary;
      font-family: $font-family-multi-display;
      letter-spacing: .71px;

      &:focus,
      &:hover {
        outline: none;
        color: $color-primary;
      }
    }
  }
</style>
