<template>
  <div class="btt-wrapper">
    <headroom footroom>
      <div
        class="btt"
        v-bind:class="{ 'btt--contact' : this.$route.name === 'contact'}"
      >
        <router-link
          class="btt__contact"
          to="/contact"
        >
          {{ $t('navigation.contact') }}
        </router-link>
        <div
          class="btt__top"
          v-on:click="scrollToTop()"
        >
          Top
        </div>
      </div>
    </headroom>
  </div>
</template>

<script>
  import { headroom } from 'vue-headroom'

  export default {
    name: 'BackToTop',
    components: {
      headroom,
    },
    methods: {
      scrollToTop: function() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    }
  }
</script>

<style scoped lang="scss">
  .btt-wrapper {
    height: 0;
    overflow: hidden;

    > div {
      height: 60px !important;
    }
  }
  .btt {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 3.75rem;
    height: 3.75rem;
    z-index: 20;
    transition: all $transition-duration*2 $transition-easing;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.17);
    color: $color-white;

    &__contact,
    &__top {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      pointer-events: all;
      justify-content: center;
      align-items: center;
      transition: all $transition-duration*2 $transition-easing;
      color: $color-white;
      background: url('../assets/images/contact.svg') no-repeat center center;
      background-size: 1.5rem;
      overflow: hidden;
      text-indent: 100vw;
      cursor: pointer;
    }

    &__top {
      pointer-events: none;
      opacity: 0;
      background: url('../assets/images/arrow-up.svg') no-repeat center center;
      background-size: 1.5rem;
    }

    .headroom & {
      opacity: 0;
      pointer-events: none;
      background: $color-primary-200;

      &:hover {
        background: $color-primary;
      }
    }

    .headroom--not-top & {
      opacity: 1;
      pointer-events: all;
    }

    .headroom--bottom &,
    &--contact {
      .btt__contact {
        pointer-events: none;
        opacity: 0;
      }
      .btt__top {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
</style>
