var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__outside"},[_c('headroom',{attrs:{"down-tolerance":1024,"speed":500,"offset":10}},[_c('div',{staticClass:"header",class:{ 'header--home': this.$route.name === 'home' }},[_c('div',{staticClass:"header__container"},[_c('div',{staticClass:"header__wrapper",class:{ 'header__wrapper--nav-active': _vm.showMobileMenu }},[_c('router-link',{staticClass:"header__logo",attrs:{"to":"/"},nativeOn:{"click":function($event){_vm.showMobileMenu = false}}},[_vm._v(" Nordic Minds ")]),_c('div',{staticClass:"header__navigation"},[_c('router-link',{staticClass:"header__navigation-link",class:{
                'header__navigation-link--active':
                  (this.$route.name === 'nordicMindsSearch')
                  | (this.$route.name === 'executiveSearch')
                  | (this.$route.name === 'boardServices'),
              },attrs:{"to":"/service"},nativeOn:{"click":function($event){_vm.showMobileMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("navigation.service"))+" ")]),_c('router-link',{staticClass:"header__navigation-link",class:{
                'header__navigation-link--active':
                  (this.$route.path.indexOf('team') > 0)
                  | (this.$route.name === 'network')
                  | (this.$route.name === 'Positioning') | (this.$route.path.indexOf('advisory-board') > 0),
              },attrs:{"to":"/about"},nativeOn:{"click":function($event){_vm.showMobileMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("navigation.about_us"))+" ")]),_c('a',{staticClass:"header__navigation-link",attrs:{"href":"https://nordic-minds.com/blog/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("navigation.insights"))+" ")]),_c('router-link',{staticClass:"header__navigation-link",attrs:{"to":"/contact"},nativeOn:{"click":function($event){_vm.showMobileMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("navigation.contact"))+" ")]),_c('div',{staticClass:"header__navigation-link"},[_c('LocaleChanger')],1),_c('a',{staticClass:"header__navigation-link header__navigation-link--login",attrs:{"href":"https://client.ezekia.com/login/nordic-minds/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("navigation.login"))+" ")])],1),_c('button',{staticClass:"header__navigation-toggle",on:{"click":function($event){_vm.showMobileMenu = !_vm.showMobileMenu}}},[_c('span',{staticClass:"header__navigation-toggle-bar header__navigation-toggle-bar--top"}),_c('span',{staticClass:"header__navigation-toggle-bar header__navigation-toggle-bar--center"}),_c('span',{staticClass:"header__navigation-toggle-bar header__navigation-toggle-bar--bottom"})])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }